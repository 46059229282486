export interface ICarouselHighlightsBorder {
  borderColor: string
}

export function CarouselHighlightsBorder({ borderColor }: ICarouselHighlightsBorder): JSX.Element {
  return (
    <>
      <hr />

      <style jsx>{`
        hr {
          border-top: 1px solid ${borderColor};
        }
      `}</style>
      <style jsx>{`
        hr {
          align-self: start;
          border-bottom: none;
          border-left: none;
          border-right: none;
          margin: 0;
        }
      `}</style>
    </>
  )
}
